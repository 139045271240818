import React from "react";
import {Control, Controller} from "react-hook-form";
import {Dropdown, mergeStyleSets, useTheme} from "@fluentui/react";
import PropInputWrapper from "./PropInputWrapper";
import {PropertyType} from "../App";

export interface Props {
    control: Control<Record<string, any>>;
    property: PropertyType;
    showButton: (show: boolean) => void;
    visible: boolean;
    inputChanged: (propertyType) => void;
};

function CustomPropDropdown(props: Props) {
    const theme = useTheme();
    const styles = mergeStyleSets({
        dropdown: {
            fontFamily: "Verdana",
        },
        container: {
            display: props.visible ? "block" : "none",
            minWidth: 200,
            flex: 1,
        }
    })
    return (
        <div className={styles.container} data-testid="customPropDropdown">
            <PropInputWrapper property={props.property}>
                <Controller
                    data-testid="customPropDropdown-controller"
                    control={props.control}
                    name={`${props.property.type}#${props.property.name}`}
                    defaultValue={props.property.value || ''}
                    render={({field}) => {
                        return <Dropdown
                            styles={{
                                dropdownItem: {
                                    backgroundColor: theme.palette.neutralLight,
                                    color: "black",
                                    "&:active": {
                                        backgroundColor: theme.palette.neutralTertiary
                                    }
                                },
                                dropdownItemSelected: {
                                    backgroundColor: theme.palette.neutralLight,
                                    color: "black",
                                    "&:focus": {
                                        backgroundColor: theme.palette.themePrimary
                                    },
                                },
                                title: {
                                    backgroundColor: theme.palette.neutralLight,
                                    color: "black",
                                },
                            }}
                            placeholder={props.property.value || ''}
                            options={props.property.options.map((option) => ({key: option, text: option}))}
                            onChange={(_, selected) => {
                                props.inputChanged(props.property);
                                props.showButton(true);
                                field.onChange(selected.key)
                                return selected.key;
                            }}
                        />
                    }}
                />
            </PropInputWrapper>
        </div>
    )
}

export default CustomPropDropdown;