import React from "react";
import {Icon, mergeStyleSets, TeachingBubble} from "@fluentui/react";
import {useBoolean, useId} from '@fluentui/react-hooks';
import {PropertyType} from "../App";
import {useTranslation} from "react-i18next";

export interface Props {
    className: string,
    property: PropertyType,
}

function PropLockVisualizer(props: Props) {
    const {t} = useTranslation();
    const styles = mergeStyleSets({
        lock: {
            width: 7,
            height: 7,
            cursor: "pointer",
            color: "black",
        }
    });
    const buttonId = useId('targetButton');
    const [teachingBubbleVisible, {toggle: toggleTeachingBubbleVisible}] = useBoolean(false);

    const teachingBubble = (headline: string, text: string) => {
        return teachingBubbleVisible && (
            <TeachingBubble
                target={`#${buttonId}`}
                hasCondensedHeadline={true}
                hasCloseButton={true}
                closeButtonAriaLabel="Close"
                onDismiss={toggleTeachingBubbleVisible}
                headline={headline}
            >{text}</TeachingBubble>)
    }

    const lockRenderer = () => {
        if (props.property.count == 0) return (
            <>
           <Icon
           iconName="Hide"    
           className={styles.lock}
           id={buttonId}
           onClick={() => toggleTeachingBubbleVisible()}
           />
                {teachingBubble(t("variableNotFoundTitle"), t("variableNotFoundBody"))}
            </>
        )
        if (props.property.countLocked == 0) return null;
        if (props.property.countLocked == props.property.count) return (
            <>
                <Icon
                    data-testid="propLockVisualizer-locked"
                    iconName="Lock"
                    className={styles.lock}
                    id={buttonId}
                    onClick={() => toggleTeachingBubbleVisible()}
                /> {teachingBubble(t("lockedCompletelyTitle"), t("lockedCompletelyBody", {count: props.property.count}))}
            </>
        )
        return <><Icon
            data-testid="propLockVisualizer-unlocked"
            iconName="Unlock"
            className={styles.lock}
            id={buttonId}
            onClick={() => toggleTeachingBubbleVisible()}
        />
            {teachingBubble(t("lockedPartlyTitle"), t("lockedPartlyBody", {
                count: props.property.count,
                countLocked: props.property.countLocked
            }))}
        </>
    }
    return <div className={props.className} data-testid="propLockVisualizer">{lockRenderer()}</div>
}

export default PropLockVisualizer;