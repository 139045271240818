import React from "react";
import Header from "./header/Header";
import SearchBar from "./SearchBar";
import Fuse from 'fuse.js';
import {ThemeProvider} from '@fluentui/react';
import {TinyColor} from '@ctrl/tinycolor';
import {darkTheme, lightTheme, ThemeIsLightContext} from "../themes";
import apiServices from "../services/api";
import CustomPropListRenderer from "./form/CustomPropListRenderer";
import {ErrorOutlet, useErrorOutlet} from "../hooks/Error";
import {LoadingOutlet, useLoadingOutlet} from "../hooks/Loading";
import i18n from '../i18n';
import {useTranslation} from "react-i18next";

export type PropertyType = { name: string, value: string, options?: string[], count: number, countLocked: number, inputChanged: boolean, types: { name: string, color: string }[], type: string };
export type LegendType = { name: string, color: string }[];

export interface Props {
    isWordOrSystemThemeLight?,
    setLanguage?,
}

const isWordOrSystemThemeLight = async () => {
    return new Promise(async (resolve) => {
        await Office.onReady();
        if (Office.context.diagnostics.platform.toString() == "PC") {
            resolve(new TinyColor(Office.context.officeTheme.controlBackgroundColor).isLight());
        } else {
            resolve(window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches);
        }
    });
}

const setLanguage = async () => {
    await Office.onReady();
    let language = Office.context.displayLanguage;
    if (language == "nl-NL") await i18n.changeLanguage("nl");
}

function App(props: Props) {
    const [properties, setProperties] = React.useState(null);
    const [legend, setLegend] = React.useState([]);
    const [selectedTypes, setSelectedTypes] = React.useState([]);
    const [filteredProperties, setFilteredProperties] = React.useState(null);
    const [buttonDisplay, setButtonDisplay] = React.useState("none");
    const [searchTermState, setSearchTermState] = React.useState("");
    const [themeIsLight, setThemeIsLight] = React.useState(true);
    const handleError = useErrorOutlet();
    const handleLoading = useLoadingOutlet();
    const {t} = useTranslation();

    React.useEffect(() => {
        props.isWordOrSystemThemeLight().then((result: boolean) => setThemeIsLight(result));
        props.setLanguage().then(() => initializeDocProps());
    }, [])

    const initializeDocProps = async () => { 
        handleLoading(t("fetchingDocProps"));
        setProperties(null);
        setFilteredProperties(null);
        let properties = new Promise<{ name: string, value: string, type: string }[]>(async (resolve) => {
            await Office.onReady();
            await Word.run((context) => {
                let customDocProps = context.document.properties.customProperties;
                context.load(customDocProps);
                context.document.untrack();
                return context.sync().then(() => {
                    resolve(customDocProps.items.map((item) => (
                        {name: item.key, value: item.value, type: "Property"}
                    )));
                });
            })
        })
        let customXml;
        let variables = new Promise<{name: string, value: string, type: string}[]>(async (resolve) => {
            customXml = await apiServices.loadDocumentCustomXml();
                resolve([...customXml.documentElement.getElementsByTagName("Variable")]
                    .filter((variable) => ( variable.getElementsByTagName("Name").length + variable.getElementsByTagName("Value").length == 2 ))
                    .map((variable) => ({ 
                        name: variable.getElementsByTagName("Name").item(0).textContent, 
                        value: variable.getElementsByTagName("Value").item(0).textContent, 
                        type: "Variable" })));
            
        });
        const tmp = await properties.then(async (props) => props.concat(await variables));
        let response = await apiServices.buildXmlPostRequest(tmp, 'initializeDocProps', 'json', customXml);
        if (response.ok) {
            response.json().then(
                promise => {
                    setProperties([...promise.docProps, ...promise.xmlVariables]);
                    setFilteredProperties([...promise.docProps, ...promise.xmlVariables]);
                    setLegend(promise.legendTypes);
                }
            )
        } else {
            handleError(new Error(`${t("failedToLoadDocProps")} ${response.message}`))
        }
        handleLoading(null);
    }

    const sort = (type: number) => {
        const compare = (type: number, input1: string, input2: string) => {
            return type == 1 ? input1 > input2 : input1 < input2;
        }
        if (type)
            setProperties([...properties].sort((a, b) => (compare(type, a.name, b.name)) ? 1 : -1));
    };

    const search = (searchTerm: string) => {
        filter(searchTerm, selectedTypes);
        setSearchTermState(searchTerm);
    }

    const updateSelectedTypes = (name: string, add: boolean) => {
        let updatedTypes;
        if (add)
            updatedTypes = selectedTypes.concat(name);
        else
            updatedTypes = selectedTypes.filter(type => type !== name);
        setSelectedTypes(updatedTypes);
        filter(searchTermState, updatedTypes);
    }

    const filter = (searchTerm: string, types: string[]) => {
        let newFilteredProperties = [];
        if (searchTerm.length == 0) newFilteredProperties = properties;
        else {
            const fuse = new Fuse(properties, {keys: ['name', 'value']});
            fuse.search(searchTerm).forEach((result) => newFilteredProperties.push(result.item));
        }
        if (types.length > 0) {
            newFilteredProperties = newFilteredProperties.filter(prop => {
                let propTypeNames = []
                prop.types.every(type => propTypeNames.push(type.name));
                return types.every(v => propTypeNames.includes(v))
            })
        }
        setFilteredProperties(newFilteredProperties);
    }

    const showButton = (show: boolean) => {
        show ? setButtonDisplay("flex") : setButtonDisplay("none");
    }

    const inputChanged = (property: PropertyType) => {
        let temp = [...properties];
        temp[temp.findIndex(prop => prop.name === property.name && prop.type === property.type)].inputChanged = true;
        setProperties(temp);
    }

    const toggleTheme = () => {
        setThemeIsLight(!themeIsLight);
    }

    return (
        <ThemeProvider applyTo="body" theme={themeIsLight ? lightTheme : darkTheme}>
            <ThemeIsLightContext.Provider value={themeIsLight}>
                <ErrorOutlet/>
                <LoadingOutlet/>
                <Header sort={sort} legend={legend} updateSelectedTypes={updateSelectedTypes}
                        toggleTheme={toggleTheme} reload={initializeDocProps}/>
                <SearchBar search={search}/>
                <CustomPropListRenderer showButton={showButton} setProperties={setProperties}
                                        properties={properties}
                                        filteredProperties={filteredProperties} buttonDisplay={buttonDisplay}
                                        inputChanged={inputChanged}/>
            </ThemeIsLightContext.Provider>
        </ThemeProvider>
    );
}

App.defaultProps = {
    isWordOrSystemThemeLight,
    setLanguage,
}

export default App;
