import React from "react";
import {mergeStyleSets} from "@fluentui/react";

export interface Props {
    className: string,
    propTypes: { name: string, color: string }[],
}

const styles = mergeStyleSets({
    dot: {
        width: 7,
        height: 7,
        borderRadius: "50%",
        marginBottom: 5,
    }
});

function PropTypeVisualiser({className, propTypes}: Props) {
    return (
        <div className={className} data-testid="propTypeVisualiser">
            {propTypes?.slice(0, 5).map((type, i) => {
                return <div key={i} className={styles.dot} style={{backgroundColor: type.color}} data-testid="propTypeVisualiser-type"/>
            })}
        </div>
    )
}

export default PropTypeVisualiser;