import React from "react";
import FilterToggle from "./FilterToggle";
import {mergeStyleSets} from "@fluentui/react";
import {LegendType} from "../App";

export interface Props {
    toggles: LegendType,
    updateSelectedTypes: (name: string, add: boolean) => void,
}

const styles = mergeStyleSets({
    container: {
        display: "flex"
    }
})

function FilterToggleList(props: Props) {
    return (
        <div className={styles.container} data-testid="filterToggleList">
            {props.toggles.map((toggle, i) => {
                return <FilterToggle key={i} label={toggle.name} color={toggle.color}
                                     updateSelectedTypes={props.updateSelectedTypes}/>
            })}
        </div>
    )
}

export default FilterToggleList;