import React from "react";
import {mergeStyleSets, Spinner, SpinnerSize, useTheme} from "@fluentui/react";

const LoadingContext = React.createContext(null);

export function LoadingDisplayBoundary({children}) {
    const [message, setMessage] = React.useState(null);
    const ctx = React.useMemo(() => ({message, setMessage}), [message])
    return <LoadingContext.Provider value={ctx}>{children}</LoadingContext.Provider>
}

export function LoadingOutlet() {
    const {message} = React.useContext(LoadingContext);
    return (
        message && (
            <LoadingComponent message={message}/>
        )
    )
}

export function useLoadingOutlet() {
    const loadingCtx = React.useContext(LoadingContext);
    return loadingCtx.setMessage;
}

export function LoadingComponent({message}: { message: string }) {
    const theme = useTheme();
    const styles = mergeStyleSets({
        container: {
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: theme.palette.white,
            padding: "10px 0",
            zIndex: 99,
        },
    })
    return <div className={styles.container}><Spinner size={SpinnerSize.large} label={message}/></div>
}

