import React from "react";
import {Icon, useTheme, mergeStyleSets} from "@fluentui/react";
import {ThemeIsLightContext} from "../../themes";

export interface Props {
    toggleTheme: () => void,
}

function ThemeToggle(props: Props) {
    const themeIsLight = React.useContext(ThemeIsLightContext);
    const theme = useTheme();
    const styles = mergeStyleSets({
        themeIcon: {
            fontSize: 20,
            margin: "10px 5px 8px 0",
            cursor: "pointer",
            userSelect: "none",
            color: theme.palette.black,
        }
    })
    return <Icon data-testid="themeToggle" iconName={themeIsLight ? "Sunny" : "ClearNight"} className={styles.themeIcon} onClick={props.toggleTheme}/>
}

export default ThemeToggle