import React from "react";
import {Icon, mergeStyleSets, useTheme} from "@fluentui/react";

export interface Props {
    reload: () => void,
}

function Reload(props: Props) {
    const theme = useTheme();
    const styles = mergeStyleSets({
        reloadIcon: {
            fontSize: 20,
            margin: "10px 5px 8px 5px",
            cursor: "pointer",
            userSelect: "none",
            color: theme.palette.black,
        }
    })
    return <Icon data-testid="reload" iconName="SyncOccurence" className={styles.reloadIcon} onClick={props.reload}/>
}

export default Reload;
