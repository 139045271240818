import React from "react";
import {mergeStyleSets, useTheme} from "@fluentui/react";
import {ThemeIsLightContext} from "../../themes";

export interface Props {
    label: string,
    color: string,
    className?: string,
    updateSelectedTypes: (name: string, add: boolean) => void,
}

function FilterToggle(props: Props) {

    const [filterState, setFilterState] = React.useState(false);
    const theme = useTheme();
    const themeIsLight = React.useContext(ThemeIsLightContext);

    const styles = mergeStyleSets({
        capsule: {
            width: 35,
            height: 10,
            borderRadius: 15,
            backgroundColor: props.color,
            margin: "auto",
        },
        label: {
            textAlign: "center",
            margin: "3px 0 0 0",
            fontFamily: "Verdana",
            fontSize: 12,
            color: themeIsLight || !filterState ? theme.palette.black : theme.palette.white,
        },
        container: {
            width: "fit-content",
            backgroundColor: filterState ? "#C4C4C4" : "none",
            padding: 5,
            borderRadius: 5,
            cursor: "pointer",
            userSelect: "none",
            margin: "0 4px 0 4px"
        },
    })

    const toggleFilter = () => {
        props.updateSelectedTypes(props.label, !filterState);
        setFilterState(!filterState);
    }

    return <div className={props.className} data-testid="filterToggle">
        <div data-testid="filterToggle-toggle" className={styles.container} onClick={toggleFilter}>
            <div className={styles.capsule}/>
            <p className={styles.label}>{props.label}</p>
        </div>
    </div>
}

export default FilterToggle;
