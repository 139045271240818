import App from "./components/App";
import {initializeIcons} from "@fluentui/react";
import React from "react";
import * as ReactDOM from "react-dom";
import {ErrorDisplayBoundary} from "./hooks/Error";
import {LoadingDisplayBoundary} from "./hooks/Loading";
/* global document, Office, module, require */

initializeIcons();

const render = (Component) => {
    ReactDOM.render(Component, document.getElementById("container"));
};

const renderApp = () => {
    render(
        <ErrorDisplayBoundary>
            <LoadingDisplayBoundary>
                <App/>
            </LoadingDisplayBoundary>
        </ErrorDisplayBoundary>
    );
};

/* Render application after Office initializes */
Office.onReady(() => renderApp());

if ((module as any).hot) {
    (module as any).hot.accept("./components/App", () => {
        const NextApp = require("./components/App").default;
        render(NextApp);
    });
}
