import React from "react";
import {DefaultButton, mergeStyleSets, PrimaryButton, useTheme} from "@fluentui/react";
import {useTranslation} from "react-i18next";

export interface Props {
    display: string,
    showButton: (show: boolean) => void,
}

function HiddenButton(props: Props) {
    const theme = useTheme();
    const {t} = useTranslation();
    const styles = mergeStyleSets({
        container: {
            display: props.display,
            position: "fixed",
            bottom: 0,
            marginBottom: 40,
            left: "50%",
            transform: "translateX(-50%)",
        },
        applyButton: {
            minWidth: 130,
            marginRight: 40,
            color: "white"
        },
        hideButton: {
            color: theme.palette.black,
        }
    })
    return <div className={styles.container} data-testid="hiddenButton">
        <PrimaryButton
            data-testid="hiddenButton-submitButton"
            type="submit"
            text={t("applyToDocument")}
            className={styles.applyButton}
        />
        <DefaultButton data-testid="hiddenButton-hideButton" className={styles.hideButton}
                       onClick={() => props.showButton(false)}>{t("hideButtons")}</DefaultButton>
    </div>
}

export default HiddenButton;