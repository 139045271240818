const parser = new DOMParser();
const serializer = new XMLSerializer();
const apiUrl = location.hostname === "localhost" ? 'https://localhost:4000' : "";
const ooxmlTypes = ["Body", "FirstPageHeader", "FirstPageFooter", "PrimaryHeader", "PrimaryFooter"];

const loadDocumentCustomXml = async () => {
    let parsed;
    await new Promise(async (resolve) => {
        await Office.onReady()
        Office.context.document.customXmlParts.getByNamespaceAsync('docgen-assistant', (namespaceParts) => {
            if (namespaceParts.value.length === 0) {
                resolve(true);
                return;
            }
            namespaceParts.value[0].getNodesAsync('*', (nodes) => {
                nodes.value[0].getXmlAsync((xml) => {
                    parsed = parser.parseFromString(xml.value, 'text/xml');
                    resolve(true);
                })
            })
        });
    })
    if (!parsed) return parser.parseFromString(`<CustomXml xmlns="docgen-assistant"><Variables/><Configuration><Types/><Properties/></Configuration></CustomXml>`, 'text/xml')
    return parsed;
}

const buildXmlPostRequest = async (propOrVarArray: { name: string, value: string, type: string }[], endpoint: string, responseType: string, customXml) => {
    const namespace = "docgen-assistant";
    const createElement = (qualifiedName: string) => {
        return doc.createElementNS(namespace, qualifiedName);
    }
    let doc = document.implementation.createDocument(namespace, "MyDocument");
    let propsAndVars = createElement("PropsAndVars");
    let propertiesElement = createElement("Properties");
    let variablesElement = createElement("Variables");
    propsAndVars.appendChild(propertiesElement);
    propsAndVars.appendChild(variablesElement);
    doc.documentElement.appendChild(propsAndVars);

    propOrVarArray.forEach(propOrVar => {
        let propOrVarElement = createElement(propOrVar.type);
        let name = createElement("Name");
        let value = createElement("Value");
        name.textContent = propOrVar.name;
        value.textContent = propOrVar.value;
        propOrVarElement.appendChild(name);
        propOrVarElement.appendChild(value);
        if (propOrVar.type == "Property") propertiesElement.appendChild(propOrVarElement);
        else variablesElement.appendChild(propOrVarElement);
    })

    doc.documentElement.appendChild(customXml.documentElement);

    let data = createElement("Data");
    let ooxml = createElement("Ooxml");
    doc.documentElement.appendChild(data);
    data.appendChild(ooxml);
    let wordXml = [];
    await Office.onReady();
    await Word.run(async (context) => {
        let sections = context.document.sections.getFirst();
        wordXml.push(context.document.body.getOoxml());
        wordXml.push(sections.getHeader(Word.HeaderFooterType.firstPage).getOoxml());
        wordXml.push(sections.getFooter(Word.HeaderFooterType.firstPage).getOoxml());
        wordXml.push(sections.getHeader(Word.HeaderFooterType.primary).getOoxml());
        wordXml.push(sections.getFooter(Word.HeaderFooterType.primary).getOoxml());
    });
    const addOoxmlData = (qualifiedName: string, xml: string) => {
        let qualifiedElement = createElement(qualifiedName);
        qualifiedElement.appendChild(
            parser.parseFromString(xml, 'text/xml').getElementsByTagNameNS("http://schemas.microsoft.com/office/2006/xmlPackage", "package")[0]);
        ooxml.appendChild(qualifiedElement);
    }
    for (let i = 0; i < ooxmlTypes.length; i++) {
        addOoxmlData(ooxmlTypes[i], wordXml[i].value);
    }
    return await fetch(`${apiUrl}/api/ooxml/${endpoint}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/xml', 'Accept': `application/${responseType}`},
        body: `<?xml version="1.0" encoding="utf-8"?>` + serializer.serializeToString(doc)
    }).then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response;
    }).catch((error) => {
        return error
    })
};

const submit = async (rawInputs: { [s: string]: string; }) => {
    const didUpdate = (index) => {
        return !!result.getElementsByTagName(ooxmlTypes[index])[0].getAttribute("Updated")
    }
    const helper = (index) => {
        return serializer.serializeToString(
            result.getElementsByTagName(ooxmlTypes[index])[0].firstChild)
    }
    let inputs = Object.entries(rawInputs).map(([key, value]) => {
        return {name: key.split("#").slice(1).join("#"), type: key.split("#")[0], value}
    });
    let result;
    try {
        await loadDocumentCustomXml().then(async (customXml) => {
            await buildXmlPostRequest(inputs, 'manipulateDocProps', 'xml', customXml)
                .then(response => response.text())
                .then(str => parser.parseFromString(str, "application/xml"))
                .then(data => result = data);
            await Office.onReady();
            await Word.run(async (context) => {
                if (didUpdate(0)) context.document.body.insertOoxml(helper(0), Word.InsertLocation.replace);
                let sections = context.document.sections.getFirst();
                if (didUpdate(1))
                    sections.getHeader(Word.HeaderFooterType.firstPage).insertOoxml(helper(1), Word.InsertLocation.replace);
                if (didUpdate(2))
                    sections.getFooter(Word.HeaderFooterType.firstPage).insertOoxml(helper(2), Word.InsertLocation.replace);
                if (didUpdate(3))
                    sections.getHeader(Word.HeaderFooterType.primary).insertOoxml(helper(3), Word.InsertLocation.replace);
                if (didUpdate(4))
                    sections.getFooter(Word.HeaderFooterType.primary).insertOoxml(helper(4), Word.InsertLocation.replace);
                inputs.forEach((input) => {
                    if (input.type == "Property") {
                        context.document.properties.customProperties.add(input.name, input.value ?? "")
                    }
                });
                await updateCustomXml(serializer.serializeToString(result.getElementsByTagName("CustomXml")[0]));
            })
        })
        return true
    } catch {
        return false
    }
}

const updateCustomXml = async (xml) => {
        Office.context.document.customXmlParts.getByNamespaceAsync("docgen-assistant", async (parts) => {
            if (parts.value.length > 0) {
                await Promise.all(parts.value.map(async (part) => {
                    await new Promise((r) => (part.deleteAsync(() => (r(true)))))
                }));
            }
            Office.context.document.customXmlParts.addAsync(xml);
        });
    return parser.parseFromString(xml, 'text/xml');
}

const apiServices = {
    loadDocumentCustomXml,
    buildXmlPostRequest,
    submit,
}

export default apiServices;
