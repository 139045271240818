import React from "react";

import {Control} from 'react-hook-form';
import CustomPropDropdown from "./CustomPropDropdown";
import CustomPropTextField from "./CustomPropTextField";
import {PropertyType} from "../App";
import {useTheme, mergeStyleSets} from "@fluentui/react";
import {useTranslation} from "react-i18next";

export interface Props {
    control: Control<Record<string, any>>;
    properties: PropertyType[];
    filteredProperties: PropertyType[];
    showButton: (show: boolean) => void;
    inputChanged: (property: PropertyType) => void;
};

function CustomPropList(props: Props) {
    React.useEffect(() => {
    }, [props.properties]);
    const theme = useTheme();
    const {t} = useTranslation();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const baseTab = {
        display: "inline-block",
        border: "1px solid transparent",
        borderBottom: "none",
        bottom: "-1px",
        position: "relative",
        listStyle: "none",
        padding: "6px 6px",
        cursor: "pointer",
    }
    const styles = mergeStyleSets({
        container: {
            WebkitTapHighlightColor: "transparent",
            color: theme.palette.black,
        },
        tabList: {
            borderBottom: "1px solid #605e5c",
            margin: "0 20px 20px",
            padding: 0
        },
        tab: {
            ...baseTab,
        },
        tabSelected: {
            ...baseTab,
            background: theme.palette.white, borderColor: "#605e5c", borderRadius: "5px 5px 0 0"
        },
        tabPanel: {
            display: "none",
            flexWrap: "wrap",
        },
        tabPanelSelected: {
            display: "flex",
            flexWrap: "wrap",
        },
        message: {
            color: theme.palette.black,
            margin: "0, 25px",
            textAlign: "center",
        }
    })

    const CustomPropFilterAndRenderer = (type: "Property"|"Variable") => {
        let hasVisibleElements = false;
        let render = props.properties?.map((property, i) => {
            let visible = (property.type == type)
                && props.filteredProperties?.some((fp) => fp.name == property.name)
            if (visible) hasVisibleElements = true;
            return property?.options ? (
                    <CustomPropDropdown key={i} visible={visible} property={property} control={props.control}
                                        showButton={props.showButton}
                                        inputChanged={props.inputChanged}/>
                ) :
                <CustomPropTextField key={i} visible={visible} control={props.control} property={property}
                                     showButton={props.showButton}
                                     inputChanged={props.inputChanged}/>
        })
        return hasVisibleElements ? render : render.concat(type == "Property" ?
            <p className={styles.message} key="onlyDocumentProperties">
                {t("onlyDocPropsHaveBeenFound", {"count": props.properties.length})}
            </p> :
            <p className={styles.message} key="OnlyMetaData">
                {t("onlyMetaDataHasBeenFound")}
            </p>);
    }
    return (
        <div className={styles.container} data-testid="customPropList">
            <div className={styles.tabList}>
                <div data-testid="customPropList-tab0" className={selectedTab == 0 ? styles.tabSelected : styles.tab}
                     onClick={() => setSelectedTab(0)}>{t("documentProperties")}
                </div>
                <div data-testid="customPropList-tab1" className={selectedTab == 1 ? styles.tabSelected : styles.tab}
                     onClick={() => setSelectedTab(1)}>{t("metadata")}
                </div>
            </div>
            <div className={selectedTab == 0 ? styles.tabPanelSelected : styles.tabPanel}>
                {CustomPropFilterAndRenderer("Variable")}
            </div>
            <div className={selectedTab == 1 ? styles.tabPanelSelected : styles.tabPanel}>
                {CustomPropFilterAndRenderer("Property")}
            </div>
        </div>
    )
}

export default CustomPropList;