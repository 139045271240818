import React from "react";

import {Icon, mergeStyleSets, useTheme} from "@fluentui/react";
import PropTypeVisualiser from "./PropTypeVisualiser";
import PropLockVisualizer from "./PropLockVisualizer";
import {PropertyType} from "../App";

export interface Props {
    property: PropertyType;
    children: React.ReactNode;
}

function PropInputWrapper(props: Props) {
    const theme = useTheme();
    const styles = mergeStyleSets({
        box: {
            position: "relative",
            margin: "0 20px 15px 20px",
            padding: "10px 20px 10px 20px",
            background: theme.palette.neutralLight,
            boxShadow: "0 4px 4px rgba(0, 0, 0, 0.2)",
        },
        name: {
            margin: "0 0 0 8px",
            fontFamily: "Verdana",
            fontSize: 14,
            color: "#777777",
            wordWrap: "break-word",
        },
        textField: {
            fontFamily: "Verdana",
            fontSize: 14,
        },
        type: {
            position: "absolute",
            top: 5,
            right: 5,
        },
        lock: {
            position: "absolute",
            top: 10,
            left: 5,
        },
        updated: {
            position: "absolute",
            bottom: 20,
            left: 5,
            color: "black",
        }
    })
    const updatedIndicator = () => {
        return props.property.inputChanged ? <Icon data-testid="propInputWrapper-updatedIndicator" iconName="Edit" className={styles.updated}/> : null;
    }
    return (
        <div className={styles.box} data-testid="propInputWrapper">
            <div className={styles.name}>
                {props.property.name}
                <PropLockVisualizer className={styles.lock} property={props.property}/>
            </div>
            {props.children}
            {updatedIndicator()}
            <PropTypeVisualiser className={styles.type}
                                propTypes={props.property.types}/>
        </div>
    )
}

export default PropInputWrapper;
