import React from "react";
import {Control, Controller} from "react-hook-form";
import {mergeStyleSets, TextField, useTheme} from "@fluentui/react";
import PropInputWrapper from "./PropInputWrapper";
import {PropertyType} from "../App";

export interface Props {
    control: Control<Record<string, any>>;
    property: PropertyType;
    showButton: (show: boolean) => void;
    visible: boolean;
    inputChanged: (propertyType) => void;
}

function CustomPropTextField(props: Props) {
    const theme = useTheme();
    const styles = mergeStyleSets({
        textField: {
            fontFamily: "Verdana",
        },
        container: {
            display: props.visible ? "block" : "none",
            minWidth: 200,
            flex: 1,
        },
    })
    return (
        <div className={styles.container} data-testid="customPropTextField">
            <PropInputWrapper property={props.property}>
                <Controller
                    control={props.control}
                    name={`${props.property.type}#${props.property.name}`}
                    defaultValue={props.property.value || ''}
                    render={({field}) => {
                        return <TextField
                            data-testid="customPropTextField-textfield"
                            styles={{
                                fieldGroup: {
                                    backgroundColor: theme.palette.neutralLight,
                                },
                                field: {
                                    color: "black",
                                }
                            }}
                            placeholder={props.property.value || ''}
                            defaultValue={props.property.value || ''}
                            underlined
                            onChange={(_, newValue) => {
                                props.inputChanged(props.property);
                                props.showButton(true);
                                field.onChange(newValue || "")
                                return newValue || ""
                            }}
                        />
                    }}
                />
            </PropInputWrapper>
        </div>
    )
}

export default CustomPropTextField;