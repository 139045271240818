import React from "react";

export const lightTheme = {
    palette: {
        themePrimary: '#148484',
        themeLighterAlt: '#f2fafa',
        themeLighter: '#cbebeb',
        themeLight: '#a3dada',
        themeTertiary: '#59b6b6',
        themeSecondary: '#259393',
        themeDarkAlt: '#127777',
        themeDark: '#0f6565',
        themeDarker: '#0b4a4a',
        neutralLighterAlt: '#e9e9e9',
        neutralLighter: '#e5e5e5',
        neutralLight: '#ffffff',
        neutralQuaternaryAlt: '#cdcdcd',
        neutralQuaternary: '#c4c4c4',
        neutralTertiaryAlt: '#bcbcbc',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#f0f0f0',
        red: "#ff0000"
    }
};

export const darkTheme = {
    palette: {
        themePrimary: '#1babab',
        themeLighterAlt: '#010707',
        themeLighter: '#041b1b',
        themeLight: '#083333',
        themeTertiary: '#106767',
        themeSecondary: '#189696',
        themeDarkAlt: '#2cb3b3',
        themeDark: '#45bfbf',
        themeDarker: '#6ed0d0',
        neutralLighterAlt: '#3c3c3c',
        neutralLighter: '#d4d4d4',
        neutralLight: '#e5e5e5',
        neutralQuaternaryAlt: '#595959',
        neutralQuaternary: '#5f5f5f',
        neutralTertiaryAlt: '#7a7a7a',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#f8f8f8',
        white: '#383838',
        red: "#ff0000"
    }
};

export const ThemeIsLightContext = React.createContext(true);