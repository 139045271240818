import React from "react";
import SortToggle from "./SortToggle";
import {mergeStyleSets} from "@fluentui/react";
import FilterToggleList from "./FilterToggleList";
import {LegendType} from "../App";
import ThemeToggle from "./ThemeToggle";
import {ThemeIsLightContext} from "../../themes";
import Reload from "./Reload";

const logoDiap = require("../../../../assets/logo-diap-filled.png");
const logoColored = require("../../../../assets/logo-filled.png");

export interface Props {
    sort: (type: number) => void,
    updateSelectedTypes: (name: string, add: boolean) => void,
    legend: LegendType,
    toggleTheme: () => void,
    reload: () => void,
}

function Header(props: Props) {
    const themeIsLight = React.useContext(ThemeIsLightContext);
    const logo = themeIsLight ? logoColored : logoDiap;
    const styles = mergeStyleSets({
        container: {
            display: "flex",
            margin: "10px 10px 5px 20px",
            paddingBottom: 10,
            paddingTop: 10,
            background: `url(${logo}) no-repeat right`,
            backgroundSize: 70,
        }
    })
    return <div className={styles.container} data-testid="header">
        <SortToggle sort={props.sort}/>
        <ThemeToggle  toggleTheme={props.toggleTheme}/>
        <Reload reload={props.reload}/>
        <FilterToggleList data-testid="header-filterToggleList" toggles={props.legend}
                          updateSelectedTypes={props.updateSelectedTypes}/>
    </div>
}

export default Header;