import React from "react";
import {mergeStyleSets, PrimaryButton, useTheme} from "@fluentui/react";

const ErrorContext = React.createContext(null)

export function ErrorDisplayBoundary({children}) {
    const [error, setError] = React.useState(null)
    const ctx = React.useMemo(() => ({error, setError}), [error])
    return <ErrorContext.Provider value={ctx}>{children}</ErrorContext.Provider>
}

export function ErrorOutlet() {
    const {error, setError} = React.useContext(ErrorContext)
    const theme = useTheme();
    const styles = mergeStyleSets({
        container: {
            position: "fixed",
            bottom: 0,
            backgroundColor: theme.palette.white,
            width: "100%",
            textAlign: "center",
            zIndex: 98,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
        },
        message: {
            color: theme.palette.red,
            fontWeight: "bold",
            maxWidth: "60%",
            wordWrap: "break-word"
        },
    })
    return (
        error && (
            <div role="alert" className={styles.container}>
                <p className={styles.message}>{error.message}</p>
                <PrimaryButton
                    type="submit"
                    text="Understood"
                    onClick={() => setError(null)}
                />
            </div>
        )
    )
}

export function useErrorOutlet() {
    const errorCtx = React.useContext(ErrorContext)
    return errorCtx.setError
}