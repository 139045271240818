import React from "react";
import {mergeStyleSets, SearchBox, useTheme} from "@fluentui/react";
import {useTranslation} from 'react-i18next';

export interface Props {
    search: (searchTerm: string) => void,
}

function SearchBar(props: Props) {
    const theme = useTheme();
    const {t} = useTranslation();
    const styles = mergeStyleSets({
        searchBox: {
            margin: "0 15px 20px 20px",
            backgroundColor: theme.palette.neutralLight,
            borderRadius: 5,
        }
    })
    return <SearchBox data-testid="searchBar" className={styles.searchBox} placeholder={t("search")}
                      styles={{field: {color: "black"}}}
                      onChange={(_, value) => props.search(value)}/>
}

export default SearchBar;