import React from "react";
import {Icon, mergeStyleSets, useTheme} from '@fluentui/react';
import {ThemeIsLightContext} from '../../themes';

export interface Props {
    sort: (type: number) => void,
}

function SortToggle(props: Props) {
    const [sortState, setSortState] = React.useState(0);
    const theme = useTheme();
    const themeIsLight = React.useContext(ThemeIsLightContext);
    const styles = mergeStyleSets({
        sortIcon: {
            fontSize: 20,
            borderRadius: 5,
            margin: "0 4px 0 5px",
            padding: "10px 5px 8px 5px",
            color: themeIsLight || !sortState ? theme.palette.black : theme.palette.white,
            backgroundColor: sortState ? "#C4C4C4" : "none",
            cursor: "pointer",
            userSelect: "none",
        }
    })
    const sortToggle = () => {
        const sortClick = () => {
            let newSortState = 0;
            if (sortState < 2) newSortState = sortState + 1;
            setSortState(newSortState);
            props.sort(newSortState);
        };
        return <Icon
            data-testid="sortToggle"
            iconName={sortState == 2 ? "Descending" : "Ascending"}
            className={styles.sortIcon}
            onClick={sortClick}
        />
    }
    return sortToggle();
}

export default SortToggle;
