import React from "react";
import CustomPropList from "./CustomPropList";
import HiddenButton from "../HiddenButton";
import {useForm} from 'react-hook-form';
import {mergeStyleSets, useTheme} from "@fluentui/react";
import {PropertyType} from "../App";
import apiServices from "../../services/api";
import {useErrorOutlet} from "../../hooks/Error";
import {useLoadingOutlet} from "../../hooks/Loading";
import {useTranslation} from "react-i18next";

export interface Props {
    showButton: (show: boolean) => void,
    setProperties: React.Dispatch<any>,
    properties: PropertyType[] | null,
    filteredProperties: PropertyType[] | null,
    buttonDisplay: string,
    inputChanged: (property: PropertyType) => void,
}

function CustomPropListRenderer(props: Props) {
    const theme = useTheme();
    const handleError = useErrorOutlet();
    const handleLoading = useLoadingOutlet();
    const {control, handleSubmit} = useForm();
    const {t} = useTranslation();
    const styles = mergeStyleSets({
        message: {
            color: theme.palette.black,
            margin: "0, 25px",
            textAlign: "center",
        }
    })

    const submit = async (rawInputs) => {
        debugger;
        handleLoading("Applying changes");
        props.showButton(false);
        props.setProperties([...props.properties].map(prop => {
            prop.inputChanged = false
            return prop;
        }))
        if (!await apiServices.submit(rawInputs)) handleError(new Error(t("failedToApplyChanges")));
        handleLoading(null);
    }

    if (!props.properties) return null;
    return props.properties.length > 0 ? (
        <form onSubmit={handleSubmit(submit)}>
            <CustomPropList control={control} properties={props.properties}
                            filteredProperties={props.filteredProperties}
                            showButton={props.showButton} inputChanged={props.inputChanged}/>
            <HiddenButton display={props.buttonDisplay} showButton={props.showButton}/>
        </form>
    ) : <p className={styles.message}
           data-testid="customPropListRenderer-noProperties">{t("noDocPropsHaveBeenFound")}</p>
}

export default CustomPropListRenderer;